<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Transacties &mdash; {{ formatIban(route.params?.iban) }}
    </h1>

    <UILoading v-if="loading" />
    <div v-if="!loading" class="py-4">
      <UITableResponsive :items="items" :headers="['Naam', 'IBAN', 'Datum', 'Bedrag', 'Mededeling']" :suffix-headers="store.getters.hasKantoorPermission ? ['Acties'] : []">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-auto" />
            <col class="w-0" v-if="store.getters.hasKantoorPermission" />
          </colgroup>
        </template>
        <template v-slot:item-Naam="{ item }">
          {{ String(item.name) }}
        </template>
        <template v-slot:item-IBAN="{ item }">
          <small class="block">{{ formatIban(item.iban) }}</small>
        </template>
        <template v-slot:item-Datum="{ item }">
          {{ dateStringLocale(item.date_booking) }}
        </template>
        <template v-slot:item-Bedrag="{ item }">
          <p class="block font-bold" :class="{ 'text-red-500': item.value < 0, 'text-green-500': item.value > 0 }">
            {{ formatPrice(item.value) }}&nbsp;{{ item.currency }}
          </p>
        </template>
        <template v-slot:item-Mededeling="{ item }">
          <small class="block" style="max-width: 600px; white-space: normal;" :class="{ 'text-gray-500 italic text-sm': !item.mededeling }">
            {{ item.mededeling || 'Geen mededeling' }}
          </small>
        </template>
        <template v-slot:item-Acties="{ item }">
          <div class="flex flex-row justify-end gap-4">
            <div v-if="item.value < 0" class="inline-flex flex-col gap-1">
              <button
                :disabled="item?.kost?.id > 0"
                class="btn small success"
                @click="handleKostLinken(item)"
              >
                Kost linken
              </button>
              <button
                :disabled="!item?.kost?.id"
                class="btn small warning"
                @click="handleKostBewerken(item)"
              >
                Kost bewerken
              </button>
            </div>
            <div class="inline-flex flex-col gap-1">
              <button
                :disabled="item.is_behandeld"
                class="btn success"
                @click="handleMarkerenAlsBehandeld(item)"
              >
                Markeren als behandeld
              </button>
              <button
                :disabled="!item.is_behandeld"
                class="btn error"
                @click="handleMarkerenAlsOnbehandeld(item)"
              >
                Markeren als onbehandeld
              </button>
            </div>
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:page="page" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'

import UILoading from '@/components/UI/Loading.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'

import { dateStringLocale } from '@/functions/formatDate'
import { formatIban } from '@/functions/formatIban'
import { formatPrice } from '@/functions/formatNumber'

import useApi from '@/hooks/useApi'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'
import { useStore } from 'vuex'

const [openFixedModal] = useModal({ fixed: true })
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const api = useApi()
const route = useRoute()
const store = useStore()

const apiData = computed(() => ({ account: route.params.iban }))
const { limit, page, offset, count, items, getData, loading } = usePaginatedApi('NORDIGEN_TRANSACTIONS_LIST', apiData)

const handleKostBewerken = (item) => {
  openFixedModal('FORM_KOST', { modelValue: item.kost, callback: getData })
}

const handleKostLinken = (item) => {
  const kost = {
    nordigen_uuid: item.uuid,
    date: dateStringLocale(item.date_booking),
    bedrag: formatPrice(Math.abs(item.value)),
  }
  openFixedModal('FORM_KOST', { modelValue: kost, callback: getData })
}

const handleMarkerenAlsIsBehandeld = (item, is_behandeld = true) => {
  api('NORDIGEN_TRANSACTIONS_BEHANDELD', { uuid: item.uuid, is_behandeld: !!is_behandeld }).then(() => {
    item.is_behandeld = !!is_behandeld
  })
}
const handleMarkerenAlsBehandeld = (item) => handleMarkerenAlsIsBehandeld(item, true)
const handleMarkerenAlsOnbehandeld = (item) => handleMarkerenAlsIsBehandeld(item, false)
</script>
